body {
    font-size: 18px;
}

.pageTitle {
    font-size: 64px;
}


.flappyContainer {
    /* color: white; */
    /* box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5); */
    background-color: rgba(255, 255, 255, 0.1) !important;
    /* border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(5px) !important; */
}


.introContainer {
    text-align: center;
}

.projectOverViewContainer {
    height: 95%;
}

.test {
    margin: auto;
    width: 80%;
    padding: 10px;
}

/* #challengeCard {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    background-color: rgba(173, 172, 172, 0.1) !important;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(5px) !important;
} */

#challengeCard {
    background-color: #fbfbfd !important;
}
@media screen and (max-width: 480px) {
    #flappyImg1 {
    margin: auto;
    width: 100%;
    padding-bottom: 7%;
    }
}