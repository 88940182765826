#recollection-image {
    max-width: 155vh;
}

#recollection2-image {
    max-width: 90%;
}

#recollection3-image {
    max-width: 90%;
}

#mobile-1 {
    max-width: 30vh;
}

#mobile-2 {
    max-width: 30vh;
    
}

#mobile-3 {
    max-width: 30vh;
}
#mobile-row {
    padding: 0;
}

#recollection3-image:hover, #recollection2-image:hover {
    opacity:0.7 !important;
    filter:alpha(opacity=70) !important;
    box-shadow: 0 0 0px #000000 !important;
}

@media screen and (max-width: 480px) {
    #recollection-image {
        max-width: 35vh;
    }
    #recollection2-image {
        max-width: 35vh;
    }
    #recollection3-image {
        padding-top: 45px;
        max-width: 35vh;
    }

    #mobile-1 {
        max-width: 10vh;
    }
    #mobile-2 {
        max-width: 10vh;
    }
    #mobile-3 {
        max-width: 10vh;
    }
}