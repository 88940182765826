@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.App {
  text-align: center;
  color: black;
  font-family: 'Roboto', 'sans-serif';
  background-color: #fbfbfd !important;
}

body {
  background-repeat: no-repeat;
  background-size: cover;
}

/*******************
      SPLASH
*******************/
.splashCard {
  color: black;
  background-color: #fbfbfd !important;
}

/* .splashCard {
  border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(5px) !important;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
} */


#cardBuffer {
  margin-top: 15px;
}

/*******************
      PROJECTS
*******************/

#atos-image {
  max-width: 200px;
}

#projectContainer {
  margin-top: 100px;
}

.mb-2 {
  color: rgb(197, 197, 197)
}

/* .projectCard {
  color: white;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(5px) !important;
} */

.projectCard, .logoGameCard {
  background-color: #fbfbfd !important;
}

#chillZoneBody {
  width: 50%;
  margin-left: 100px;
  margin-right: 10px;
}

#chillzone-image {
  max-width: 54vh;
}

#lvjj-online-image {
  max-width: 50vh;
}

#rec-image {
  max-width: 25vh;
}

@media screen and (max-width: 480px) {
  #atos-image {
    max-width: 150px;
  }

  #rec-image {
    max-width: 30vh;
    max-height: 30vh;
  }

  #lvjj-online-image {
    max-width: 40vh;
    max-height: 95%;
  }
  
  #chillzone-image {
    max-width: 43vh;
  }

  #chillZoneBody {
    margin-left: 5px;
  }
}

#flappybirdBody {
  width: 50%;
  margin-left: 25px;
  margin-right: 10px;
}

/* .logoGameCard {
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
  border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(5px) !important;
} */

#logoGameBody {
  width: 50%;
  margin-left: 25px;
  margin-right: 10px;
}

/*******************
      FOOTER
*******************/

.footerTest {
  width: 100%;
}

.footerLink {
  text-decoration: none;
}

.footerTesting {
  /* border: 1px solid white; */
  float: left
}

/*******************
      CONTACT
*******************/
.firstNameBox, .lastNameBox, .infoBox {
  border-radius: 16px;
  width: 100%;
  padding: 9px; 
  display: block;
  border: 1px solid;
  background-color: rgb(250, 250, 250);
}

.contactBtn {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-color: #1266F1 !important;
  color: white !important;
  background-color: #1266F1;
  border: solid;
  border-radius: 8px;
  text-align: center;
}

.divider {
  width: 10px;
  height:auto;
  display:inline-block;
}

.firstName-col {
  position: relative;
  right: 11px;
}

.lastName-col {
  position: relative;
  left: 11px;
}

/*******************
      BLOG
*******************/

#blogAnnouncement {
font-size: 64px;
}
