.divider {
    width: 10px;
    height:auto;
    display:inline-block;
}

#account, #university-tee {
    border: solid 1px black;
}

#lvjj-index:hover, #university-tee:hover, #account:hover, #journey-map:hover, #swot-analysis:hover {
    opacity:0.7 !important;
    filter:alpha(opacity=70) !important;
    box-shadow: 0 0 0px #000000 !important;
}

@media screen and (max-width: 480px) {
    #journey-map {
       width: 75%;
       height: 75%;
    }
}