#pageTitle {
    font-size: 64px;
}

/* .firstDiv, .secondDiv {
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(5px) !important;
} */

@media screen and (max-width: 480px) {
    .prototypeImg {
        margin: auto;
        width: 100%;
        padding-bottom: 7%;
        height: 160px !important;
    }
    .phoneImg {
        margin: auto;
        padding-bottom: 7%;
        height: 300px; 
    }
}

@media screen and (max-width: 880px) {
    .prototypeImg {
        margin: auto;
        width: 75%;
        padding-bottom: 7%;
        height: 300px;  
    }
}

@media screen and (max-width: 1220px) {
    .prototypeImg {
        margin: auto;
        width: 75%;
        padding-bottom: 7%;
        height: 400px;  
    }
}
