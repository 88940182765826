#hospital {
    max-width: 45vh;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

#journey-text {
    font-size: 16px;
    padding-top: 20px;
}

@media screen and (max-width: 480px) {
    #hospital {
        max-width: 35vh;
    }
}