#family-image {
    max-width: 55vh;
}

#subtitle-tag {
    color: lightgray;
    font-size: 20px;
}

#hospital-image {
    max-width: 55vh;
    border-radius: 12px;
}

#ahcc-image {
    max-width: 55vh;
    border-radius: 12px;
}

#ndoc-image {
    max-width: 55vh;
    border-radius: 12px;
}

#wedding {
    max-width: 105vh;
    border-radius: 12px;
}

#image-text {
    color: gray;
    font-size: 14px;
    padding-top: 10px;
}

#self-portrait {
    max-width: 55vh;
    border-radius: 12px;
}

#hospital-image:hover, #ahcc-image:hover, #ndoc-image:hover, #wedding:hover {
    opacity:0.7 !important;
    filter:alpha(opacity=70) !important;
    box-shadow: 0 0 0px #000000 !important;
}

@media screen and (max-width: 480px) {
    #family-image {
        max-width: 25vh;
    }
    #hospital-image {
        max-width: 25vh;
    }
    #ahcc-image {
        max-width: 25vh;
    }
    #ndoc-image {
        max-width: 25vh;
    }
    #wedding {
        max-width: 40vh;
    }
    #self-portrait {
        max-width: 25vh;
    }
}