#atos-swot {
    max-width: 75%;
    height: auto;
}

#atos-userflow {
    max-width: 75%;
}

#xguard-image {
    max-width: 55%;
}

#check-in {
    padding-right: 10px;
}

.checking-in-container {
    border: solid 1px black;
    background-color: #dddddd;
    padding-bottom: 20px;
    padding-top: 10px;

}

#final-design {
    max-width: 15%;
}