#pageTitle {
    font-size: 64px;
}

#login-desktop {
    max-width: 50%;
}

#login-mobile, #friends-mobile {
    max-width: 50%;
    max-height: 425px;
}

#friends-mobile, #dashboard-desktop {
    max-width: 50%;
}

#color-palette {
    border: solid 1px black;
    background: none;
}

#wireframe:hover, #spotify-desktop:hover, #twitch-desktop:hover, #dashboard-desktop:hover, #friends-mobile:hover, #login-desktop:hover, #login-mobile:hover, #gamepage-desktop:hover {
    opacity:0.7 !important;
    filter:alpha(opacity=70) !important;
    box-shadow: 0 0 0px #000000 !important;
}

#gamepage-desktop {
    max-width: 990px;
}

@media screen and (max-width: 480px) {
    #login-desktop, #dashboard-desktop, #twitch-desktop, #gamepage-desktop {
        max-width: 90%;
    }

    #twitch-desktop {
        max-width: 90%;
        max-height: 205px;
    }

    #spotify-desktop {
        max-width: 90%;
        max-height: 205px;
    }

    #color-palette {
        max-width: 80%;
    }

    #wireframe {
        max-width: 90%;
        max-height: 450px;
    }

    #chillzoneGif {
        max-height: 60%;
        max-width: 80%;
    }
}
